import CommingSoonStyle from "../CommingSoon/CommingSoon.module.css";
import logo from "../../Assets/small-logo.png";
import fullLogoMObile from "../../Assets/full-logo-mobile.png";
import fullLogoBigger from "../../Assets/full-logo-xl-2.png";
import vaseAndFlowers from "../../Assets/vase-flowers-mobile.png";
import vaseAndFlowers500 from "../../Assets/vase-flowers-555.png";
import { Button, Image } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

const CommingSoon = () => {
  const matchMobile = useMediaQuery("(max-width: 500px)");
  const headerWrapper = CommingSoonStyle.headerWrapper;
  const logoImg = CommingSoonStyle.logoImg;
  const fullLogoStyle = CommingSoonStyle.fullLogoStyle;
  const vaseFlowers = CommingSoonStyle.vaseFlowers;
  const name = CommingSoonStyle.name;
  const title = CommingSoonStyle.title;
  const btn = CommingSoonStyle.btn;

  const titleWrapper = CommingSoonStyle.titleWrapper;

  const btnWrapper = CommingSoonStyle.btnWrapper;

  return (
    <>
      <div className={headerWrapper}>
        {matchMobile ? (
          <Image src={logo} className={logoImg} />
        ) : (
          <Image src={logo} className={logoImg} />
        )}

        <div className={titleWrapper}>
          <div>
            {matchMobile ? (
              <Image
                src={fullLogoMObile}
                alt="logo"
                className={fullLogoStyle}
              />
            ) : (
              <Image src={fullLogoBigger} className={fullLogoStyle} />
            )}
            <br />
            <h1 className={name} style={{ color: "#41294c" }}>Website Coming Soon</h1>
            <br />
          </div>
        </div>
        {matchMobile ? (
          <Image src={vaseAndFlowers} className={vaseFlowers} />
        ) : (
          <Image src={vaseAndFlowers500} className={vaseFlowers} />
        )}
      </div>
    </>
  );
};

export default CommingSoon;
